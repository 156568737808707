html, body, #root {
    height: 100%;
    margin: 0;
    padding: 0;
    background: linear-gradient(180deg, #28234F 0%, #685FA9 65.1%, #7787B6 85.42%, #89B7C6 100%) fixed;
    user-select: none;
    -webkit-user-select: none; /* Safari용 */
    -moz-user-select: none; /* Firefox용 */
    -ms-user-select: none; /* 구버전 IE용 */
    font-family: 'Pretendard', -apple-system, BlinkMacSystemFont, system-ui, Roboto, 'Helvetica Neue', 'Segoe UI', 'Apple SD Gothic Neo', 'Noto Sans KR', 'Malgun Gothic', sans-serif;
}

@font-face {
    font-family: 'Pretendard';
    src: url('./assets/fonts/Pretendard-Thin.woff2') format('woff2'),
    url('./assets/fonts/Pretendard-Thin.woff') format('woff');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Pretendard';
    src: url('./assets/fonts/Pretendard-ExtraLight.woff2') format('woff2'),
    url('./assets/fonts/Pretendard-ExtraLight.woff') format('woff');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Pretendard';
    src: url('./assets/fonts/Pretendard-Light.woff2') format('woff2'),
    url('./assets/fonts/Pretendard-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Pretendard';
    src: url('./assets/fonts/Pretendard-Regular.woff2') format('woff2'),
    url('./assets/fonts/Pretendard-Regular.woff') format('woff');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Pretendard';
    src: url('./assets/fonts/Pretendard-Medium.woff2') format('woff2'),
    url('./assets/fonts/Pretendard-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Pretendard';
    src: url('./assets/fonts/Pretendard-SemiBold.woff2') format('woff2'),
    url('./assets/fonts/Pretendard-SemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Pretendard';
    src: url('./assets/fonts/Pretendard-Bold.woff2') format('woff2'),
    url('./assets/fonts/Pretendard-Bold.woff') format('woff');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Pretendard';
    src: url('./assets/fonts/Pretendard-ExtraBold.woff2') format('woff2'),
    url('./assets/fonts/Pretendard-ExtraBold.woff') format('woff');
    font-weight: 800;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Pretendard';
    src: url('./assets/fonts/Pretendard-Black.woff2') format('woff2'),
    url('./assets/fonts/Pretendard-Black.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}